import React from 'react';
import Loadable from 'react-loadable'

import Dashboard from './views/Pages/Dashboard';

function Loading() {
  return <div><i className="text-white"> </i> Loading...</div>;
}



const Menu = Loadable({
  loader: () => import('./views/Scaffolds/Loyalty'),
  loading: Loading,
});

const routes = [
  { path: '/:load?', exact: false, name: 'Home', component: Menu} 
];

export default routes;
