import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Axios from 'axios';
import routes from '../../../routes';
import { CircularProgress } from '@rmwc/circular-progress';
import footer from '../../../assets/img/footer.png'




class Dashboard extends Component {

  constructor(props){
    super(props)

    this.state = {
      timeout:1000 * 60 * 15,//15 mins
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      loading: false
    }
  }

  handleChange (event) {
    this.setState( {[event.target.name]: event.target.value} )
  }


  handleClose() {
    this.setState({showModal: false})
  }

  render() { 
    //const {loading} = this.state;  
    var _this = this;
    return (
      <div className="container">
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (<Route key={idx} path={route.path} history={_this.props.history}  exact={route.exact} name={route.name} render={props => (
              <route.component {...props} />
            )} />)
            : (null);
            },   
          )} 
        </Switch>
        <div className="ftr"><a href="https://trueblu.co"><img src={footer} /></a></div>
      </div>
      
    );
  }
}

export default Dashboard;
