import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


//v3.2
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Font Awesome Icons Set
import '@fortawesome/fontawesome-free/css/all.css';


//necessary evils
import 'material-components-web/dist/material-components-web.min.css';
import '@rmwc/circular-progress/circular-progress.css';
import '@material/button/dist/mdc.button.css';
import '@material/toolbar/dist/mdc.toolbar.css';
import '@material/dialog/dist/mdc.dialog.css';

// Import Main styles for this application
import './assets/css/tb-fonts.css'
import './assets/css/style.css'

//import Main scaffolding
import {Login,Landing,Outlet,Dashboard} from './views/Pages';
 
class App extends Component {
  render() {
    return (
      <BrowserRouter>   
        <Switch>
          <Route path="/:load?" name="Loyalty" component={Dashboard} />
        </Switch> 
      </BrowserRouter>
    );  
  }
}
export default App;
